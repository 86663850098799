<template>
  <div>
    <b-card>
      <Table
        :loader="tableLoader"
        :items="items.results"
        :fields="fields"
        :sortBy="sortAcs"
        :sortAcs="sortAcs"
        :sortDesc="sortDesc"
        :getDocuments="getReport"
        :total="total"
        :type="typeDocument"/>
      <Paginate
        :current="items.current"
        :next="items.next"
        :prev="items.previous"
        :count="items.count"
        :changePage="getReport" />
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ReportDistributionDocType',
  components: {
    Table: () => import('@/components/layouts/Table/Table.vue'),
    Paginate: () => import('@/components/atoms/Paginate.vue')
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fields: [
        { key: 'name',
          label: this.$t('typeDoc')
        },
        { key: 'distribution_sum',
          label: this.$t('distribution')
        },
        { key: 'profit_sum',
          label: this.$t('profit')
        },
        { key: 'event',
          label: this.$t('go'),
          class: 'mw-0'
        }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      total: {}
    }
  },
  mounted () {
    this.getReport()
  },
  computed: {
    typeDocument () {
      return this.$router.currentRoute.params['typeDoc'] === 'adv_training'
        ? 'reportDistributionAdvanceTraining' : 'reportBO'
    }
  },
  methods: {
    getReport (link = null) {
      this.tableLoader = true
      const typeDoc = this.$router.currentRoute.params['typeDoc']
      const urlId = this.$router.currentRoute.params['firstIdEntry']

      const url = link || `api/v1/reports/back_office/distribution/${typeDoc}/type_document/?crewing_group=${urlId}&${this.search}`
      this.$api.get(url).then(response => {
        let cloneResponce = { ...response.data.results }
        delete cloneResponce.data
        this.total = cloneResponce
        this.tableLoader = false
        if (response.code === 200) {
          response.data.results.data[0].map(item => {
            item.searchParams = this.search
            item.link = `/report/distribution/usual/?type_document=${item.type}&crewing_group=${urlId}&search=${this.search}`
          })
          this.items = response.data
          this.items.results = response.data.results.data[0]
        }
      })
    }
  }
}
</script>
